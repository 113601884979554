<template>
  <div class="">
    <!-- 各类告警统计 -->
    <dv-border-box-12>
      <e-chart :options="options" id="box" height="340px" width="100%"></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
  import EChart from '@/common/echart/index.vue'
  import { mapState } from 'vuex'
  import i18nMixin from '@/mixins/i18nMixin'

  export default {
    mixins: [i18nMixin],
    components: { EChart },
    data() {
      return {
        options: {}
      }
    },
    computed: {
      ...mapState({
        alertData: state => state.pondAlerts
      })
    },
    watch: {
      // 监听 alertData 的变化
      alertData: {
        handler(newValue) {
          // 使用 JSON 方法来深拷贝和打印对象的非响应式副本
          const rawData = JSON.parse(JSON.stringify(newValue))
          // 处理 alertData，然后更新 processedAlertData
          this.options = this.processAlertData(rawData)
          // console.log(rawData);
        },
        deep: true, // 如果 alertData 是对象或数组，设置 deep 为 true 来进行深度监听
        immediate: true // 立即执行一次处理函数
      }
    },
    methods: {
      processAlertData(data) {
        const dict = {
          0: { name: this.$t('offline'), value: 0, color: '#f44336' }, // 红色
          1: { name: this.$t('aging'), value: 0, color: '#e91e63' }, // 粉红色
          5: { name: this.$t('overload'), value: 0, color: '#9c27b0' }, // 紫色
          6: { name: this.$t('overvoltage'), value: 0, color: '#673ab7' }, // 深紫色
          7: { name: this.$t('powerLoss'), value: 0, color: '#3f51b5' }, // 靛蓝色
          8: { name: this.$t('lackInput'), value: 0, color: '#2196f3' }, // 蓝色
          13: { name: this.$t('lackOutput'), value: 0, color: '#03a9f4' }, // 浅蓝色
          14: { name: this.$t('overTemperature'), value: 0, color: '#00bcd4' }, // 青色
          15: { name: this.$t('lowDOThreshold'), value: 0, color: '#009688' }, // 蓝绿色
          16: { name: this.$t('lowDO'), value: 0, color: '#4caf50' }, // 绿色
          17: { name: this.$t('phAbnormal'), value: 0, color: '#8bc34a' }, // 浅绿色
          18: { name: this.$t('deadlineDO'), value: 0, color: '#cddc39' } // 酸橙绿色
        }

        if (data.length) {
          data.forEach(item => {
            const { type } = item
            if (dict[type]) {
              dict[type].value += 1
            }
          })
        }
        // console.log("dict after", dict);
        // 将dict转换为数组格式
        const dataArr = Object.keys(dict).map(type => {
          return {
            type: parseInt(type),
            name: dict[type].name,
            value: dict[type].value,
            color: dict[type].color
          }
        })

        const half = Math.ceil(dataArr.length / 2)
        const leftData = dataArr.slice(0, half)
        const rightData = dataArr.slice(half).map(item => {
          return { name: item.name, value: -item.value, color: item.color }
        })

        const legendOffsetX = 20
        const legendOffsetY = 70
        const customLegend = dataArr.map((item, idx) => ({
          type: 'rect',
          shape: {
            x: legendOffsetX,
            y: legendOffsetY + idx * 20,
            width: 10,
            height: 10
          },
          style: {
            fill: item.color
          }
        }))

        const customLegendText = dataArr.map((item, idx) => ({
          type: 'text',
          style: {
            text: item.name,
            x: 20 + legendOffsetX,
            y: legendOffsetY + idx * 20,
            fill: item.color,
            fontSize: 12
          }
        }))
        return {
          title: {
            text: this.$t('alertStatistics'),
            top: '3%',
            left: '3%'
          },
          legend: {
            show: false
          },
          graphic: customLegend.concat(customLegendText),
          yAxis: {
            type: 'value',
            axisLabel: {
              show: true, // 修改为true来显示标签
              formatter: function (value) {
                return Math.abs(value) // 显示绝对值
              }
            }
          },
          xAxis: {
            type: 'category',
            axisLabel: {
              show: false // 隐藏y轴的标签
            }
          },
          grid: {
            left: '30%',
            right: '10%',
            bottom: '12%',
            top: '12%'
          },
          series: [
            {
              name: 'Top',
              type: 'bar',
              stack: 'total',
              barWidth: 30, // 设置柱子宽度
              // barGap: "15%", // 设置柱子间距
              itemStyle: {
                color: function (params) {
                  return leftData[params.dataIndex].color
                }
              },
              label: {
                show: true,
                position: 'inside',
                fontSize: 16, // 设置字体大小为16
                formatter: function (params) {
                  return Math.abs(params.value)
                }
              },
              tooltip: {
                trigger: 'item',
                formatter: function (params) {
                  return `${leftData[params.dataIndex].name}: ${Math.abs(params.value)}`
                }
              },
              data: leftData.map(item => item.value)
            },
            {
              name: 'Bottom',
              type: 'bar',
              stack: 'total',
              barWidth: 30, // 设置柱子宽度
              // barGap: "15%", // 设置柱子间距
              itemStyle: {
                color: function (params) {
                  return rightData[params.dataIndex].color
                }
              },
              label: {
                show: true,
                position: 'inside',
                fontSize: 16, // 设置字体大小为16
                formatter: function (params) {
                  return Math.abs(params.value)
                }
              },
              tooltip: {
                trigger: 'item',
                formatter: function (params) {
                  return `${rightData[params.dataIndex].name}: ${Math.abs(params.value)}`
                }
              },
              data: rightData.map(item => item.value)
            }
          ]
        }
      }
    }
  }
</script>
