<template>
  <div>
    <dv-border-box-12 class="relative">
      <!-- 分页pagination -->
      <div class="dot-indexs">
        <div
          v-for="(item, index) in sensorList"
          :key="index"
          :class="{
            'dot-center': true,
            dot: true,
            'dot-active': selectedPondPage === index
          }"
          @click="handleSelectPond(item)"
        >
          {{ index + 1 }}
        </div>
      </div>
      <div class="fake-pond-title absolute">{{ currentPondName }}</div>
      <div class="absolute-center" v-if="loading">{{ $t('loading') }}</div>
      <div class="absolute-center" v-else-if="noData">{{ $t('noData') }}</div>
      <e-chart v-else :options="options" id="box" height="350px" width="100%"></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
  import EChart from '@/common/echart/index.vue'
  import { getAllPondMachineStatus, getPondMachineList,waterQuality } from '@/api/modules/operation'

  export default {
    components: { EChart },
    data() {
      return {
        loading: false,
        options: {},
        pondsData: [],
        sensorList: [],
        selectedPondPage: -1,
        currentPondName: '',
        noData: false
      }
    },
    mounted() {
      this.fetchAllMachines()
      if (this.selectedPondPage === -1) {
        // this.fetchData()
      }
    },
    methods: {
      // 选择塘口按钮
      handleSelectPond(pond) {
        this.fetchData(pond.fishpondId)
        this.currentPondName = pond.name
      },
      async fetchAllMachines() {
        const result = await getPondMachineList()
        const sensorListData = []
        result.data.forEach(pond => {
          if (pond.deviceFishpondSensorRspVos && pond.deviceFishpondSensorRspVos.length > 0) {
            pond.deviceFishpondSensorRspVos.forEach(sensor => {
              sensorListData.push(sensor)
            })
          }
        })
        console.log('sensorListData', sensorListData)
        this.sensorList = sensorListData
        this.fetchData(sensorListData[0].fishpondId)
      },
      async fetchData(pondId) {
        this.loading = true
        try {
          const res = await getAllPondMachineStatus(pondId)
          const params = {
              fishpondId: pondId,
            }
          let res1 = await waterQuality(params) //新接口。待调试
          console.log(res1,'res1')
          const { data } = res
          if (data.historyList.length === 0) {
            this.loading = false
            this.noData = true
            return
          } else {
            this.noData = false
          }
          const rawData = data.historyList[0] && data.historyList[0].pondHistoryList
          // console.log("各测点数据", rawData);
          this.dataList = data.historyList
          // console.log("各测点数据", rawData);
          rawData &&
            rawData.length > 0 &&
            rawData.sort((a, b) => {
              // 将 createTime 转换为 Date 对象进行比较
              let dateA = new Date(a.createTime)
              let dateB = new Date(b.createTime)
              return dateA - dateB // 升序
            })

          // 1. Extract and format the x-axis data (createTime) to "MM-DD"
          const xData = rawData.map(item => {
            const date = item.createTime
            const monthDay = date.substring(5, 10) // 提取 "MM-DD"
            const time = date.substring(11, 16) // 提取 "HH:mm"
            return `${monthDay} ${time}`
          })

          // 2. Extract y-axis data for each of the desired properties
          const temperature = rawData.map(item => item.temperature||'N/A')
          const dissolvedOxygen = rawData.map(item => item.dissolvedOxygen||'N/A')
          const phData = rawData.map(item => item.ph||'N/A')
          const ammonianitrogen = rawData.map(item => item.nh3||'N/A')//假数据，待调试
          const nitrite = rawData.map(item => item.no2||'N/A')//亚硝酸盐 :no2 假数据，待调试
          // 3. Setup ECharts options
          const option = {
            title: {
              text: this.$t('allPositionSensor'),
              show: true,
              top: '3%',
              left: '3%'
            },
            legend: {
              data: [this.$t('waterTemperature'), this.$t('dissolvedOxygen'), this.$t('ph'),this.$t('ammonianitrogen'),this.$t('nitrite')],
              top: '5%',
              left: '40%'
            },
            grid: {
              top: '15%',
              bottom: '10%'
            },
            xAxis: {
              type: 'category',
              data: xData
            },
            yAxis: {
              type: 'value'
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'line'
              },
              formatter: function (params) {
                let result = params[0].axisValue + '<br>' // This will display the date
                params.forEach(param => {
                  result += `${param.seriesName}: ${param.value}<br>`
                })
                return result
              }
            },
            series: [
              {
                name: this.$t('waterTemperature'),
                type: 'line',
                smooth: true,
                data: temperature
              },
              {
                name: this.$t('dissolvedOxygen'),
                type: 'line',
                smooth: true,
                data: dissolvedOxygen
              },
              {
                name: this.$t('ph'),
                type: 'line',
                smooth: true,
                data: phData
              },
              {
                name: this.$t('ammonianitrogen'),
                type: 'line',
                smooth: true,
                data: ammonianitrogen
              },
              {
                name: this.$t('nitrite'),
                type: 'line',
                smooth: true,
                data: nitrite
              }
            ]
          }

          this.options = option
          this.loading = false
        } catch (error) {
          console.error('Error fetching data:', error)
        }
      },
      updateChart() {}
    }
  }
</script>

<style scoped>
  .data-sets {
    position: absolute;
    top: 5%;
    right: 5%;
  }
  .fake-pond-title {
    right: 5%;
    top: 5%;
  }
  .dot-indexs {
    position: absolute;
    display: flex;
    z-index: 1;
    gap: 10px;
    right: 0;
    top: -25px;
  }
  .dot {
    background-color: rgb(222, 222, 222);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .dot-center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .dot.dot-active {
    background-color: white;
    color: black;
  }
  .absolute-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
