<template>
  <div class="">
    <dv-border-box-12>
      <!-- <div class="">渔机告警列表</div> -->
      <dv-scroll-board :config="scrollData" style="width: 100%; height: 350px" />
    </dv-border-box-12>
  </div>
</template>

<script>
  import { getDeviceAlert } from '@/api/modules/operation'
  import i18nMixin from '@/mixins/i18nMixin'

  export default {
    mixins: [i18nMixin],
    data() {
      return {
        interval: null, // 定时器
        scrollData: {}
      }
    },
    mounted() {
      this.fetchData() // 立即执行一次
      this.interval = setInterval(this.fetchData, 15000) // 然后每5秒执行一次
    },
    beforeDestroy() {
      clearInterval(this.interval) // 清除定时器
    },
    methods: {
      async fetchData() {
        try {
          const res = await getDeviceAlert()
          this.$store.dispatch('processDeviceAlerts', res.data)
          console.log('processDeviceAlerts', res.data)
          const list = res.data.slice(0, 10)
          console.log(list[0].occurTime.slice(5, 16))
          this.scrollData = {
            header: [
              this.$t('object'),
              this.$t('alert'),
              this.$t('occurTime'),
              this.$t('alertTime')
            ],
            data: list.map(item => {
              return [
                item.relayNames,
                item.typeName,
                item.occurTime?.slice(5, 16) || '',
                item.recoverTime?.slice(5, 16) || ''
              ]
            }),
            rowNum: 10
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
