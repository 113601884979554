import http from '../index.js'

/**
 * 获取运营一张网上方5个数据的接口
 * @returns
 */
export const getOperationDataApi = () => {
  const url = `/web/fullScreen/banner`
  return http.get(url)
}

/**
 * 获取鱼塘状态数据  - 塘口状态(需轮询)
 * @returns
 */
export const getPondStatusApi = () => {
  const url = `/web/fullScreen/fishpondStatus`
  return http.get(url)
}

export const addPondDistribution = params => {
  const url = `/web/fullScreen/distribution/add`
  return http.post(url, params)
}

// 获取所有塘口
export const getPondMachineList = () => {
  const url = `/web/fullScreen/distribution/list-all-fishponds-and-machines`
  return http.get(url)
}

// 查询某个布局下的所有塘口
// /web/fullScreen/distribution/list-bounds-by-distributionId?distributionId=9
export const getPondMachineListByDistributionId = distributionId => {
  if (!distributionId) {
    return Promise.reject(new Error('distributionId is required'))
  }
  const url = `/web/fullScreen/distribution/list-bounds-by-distributionId?distributionId=${distributionId}`
  return http.get(url)
}

// 获取布局分页信息
export const getPondDistribution = () => {
  const url = `/web/fullScreen/distribution/list`
  return http.get(url)
}

// web/fullScreen/distribution/delete?id=1
export const deletePondDistribution = id => {
  const url = `/web/fullScreen/distribution/delete?id=${id}`
  return http.post(url)
}

// 传三种数据，分别解绑鱼塘，机器，传感器
// 1. 鱼塘
// "unbindId":1406.
// "unbindType": "fishpond"
// 2. 增氧机
// "unbindType":"aerator",
// "mac":"35878342"，
// "relayType":1
// 3. 传感器
// "unbindId":10,
// "unbindType":"sensor'

/**
 * 解除塘口绑定
 * @param {Object} params
 * @param {Number} params.unbindId 解绑id
 * @param {String} params.unbindType 解绑类型
 * @param {String} params.mac 机器mac地址
 * @param {Number} params.relayType 机器类型
 * @returns
 */
export const unbindPondDistribution = params => {
  const url = `/web/fullScreen/distribution/unbind-fishpond-or-machine`
  return http.post(url, params)
}

// {
//   "distributionId":5,
//   "fishpondId":1276,
//   "fishpondIndex":1
// }

/**
 * 绑定鱼塘到分布
 * @param {Object} params
 * @param {Number} params.distributionId 分布id
 * @param {Number} params.fishpondId 鱼塘id
 * @param {Number} params.fishpondIndex 鱼塘索引
 * @returns
 */
export const bindPondToDistribution = params => {
  const url = `/web/fullScreen/distribution/bind-fishpond`
  return http.post(url, params)
}

// /web/fullScreen/distribution/bind-fishpond-machine
// "fishpondId":1404, 鱼塘id
// "mac":"75203136",机器mac地址
// "relayType": 1,   控制器接口位置
// "fishMachineIndex":1,  机器位置索引
// "fishMachineType":"aerator"  机器类型  - areator 增氧机  - feeder 投料机

/**
 * 绑定机器到鱼塘
 * @param {Object} params
 * @param {Number} params.fishpondId 鱼塘id
 * @param {String} params.mac 机器mac地址
 * @param {Number} params.relayType 控制器接口位置
 * @param {Number} params.fishMachineIndex 机器位置索引
 * @param {String} params.fishMachineType 机器类型
 * @returns
 */
export const bindMachineToPond = params => {
  const url = `/web/fullScreen/distribution/bind-fishpond-machine`
  return http.post(url, params)
}

/**
 * 获取鱼塘状态数据2  - 塘口状态(需轮询) 获取所有的鱼塘以及下面的机器信息
 * @returns
 */
export const getAllPondApi = () => {
  const url = `/web/fishpond/list`
  return http.get(url)
}

/**
 * 机电水质报警/动态告警列表
 */

export const getMachineAlertApi = () => {
  const url = `/web/fullScreen/plumber`
  return http.get(url)
}

/**
 * 基塘-塘口统计
 */

export const getPondMachineCount = () => {
  const url = `web/fullScreen/statisticsFishpond`
  return http.get(url)
}

/**
 *  各测点数据
 */
export const getAllPondMachineStatus = id => {
  const url = `/web/fullScreen/statisticsFishpondData?fishpondId=${id}`
  return http.get(url)
}
/**
 *  塘口--渔机种类
 */
export const getPondMachines = () => {
  const url = `/web/fullScreen/statisticsFishpondType`
  return http.get(url)
}

/**
 *  告警时间统计
 */
export const getDeviceAlert = () => {
  const url = `/web/fullScreen/distribution/device-alert`
  return http.get(url)
}

/**
 *  各测点数据--新接口20250225
 */
export const waterQuality = params => {
  const url = `/web/fullScreen/fishpond/waterQuality`
  return http.post(url, params)
}