// src/mixins/i18nMixin.js
export default {
    computed: {
      language() {
        return this.$store.state.language;
      }
    },
    watch: {
      language(newLang) {
        this.$i18n.locale = newLang;
      }
    }
  };
  