<template>
  <div>
    <dv-border-box-12 class="relative">
      <div class="title">{{ $t('pondSummary') }}</div>
      <e-chart :options="options" id="box" height="350px" width="100%"></e-chart>
    </dv-border-box-12>
  </div>
</template>

<script>
  import EChart from '@/common/echart/index.vue'
  import { getPondMachineCount } from '@/api/modules/operation'

  export default {
    components: { EChart },
    data() {
      return {
        options: {}
      }
    },
    mounted() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        try {
          const result = await getPondMachineCount()
          // console.log("基塘-塘口统计 getPondMachineCount ", result);
          const { data } = result
          let ponds = []
          if (data.length > 10) {
            // 前拿出前9个塘口数据
            ponds = data.slice(0, 9).map(pond => {
              return {
                value: parseInt(pond.area),
                name: pond.name
              }
            })
            // 从第10个开始，合并为其他
            let other = data.slice(9).reduce((acc, item) => {
              return acc + parseInt(item.area)
            }, 0)
            ponds.push({
              value: other,
              name: this.$t('other')
            })
          } else {
            // 少于10个塘口直接展示
            ponds = data.map(pond => {
              return {
                value: parseInt(pond.area),
                name: pond.name
              }
            })
          }

          const totalArea = data.reduce((acc, item) => {
            let val = parseInt(item.area)
            if (!val) {
              val = 0
            }
            return acc + parseInt(val)
          }, 0)
          // console.log(totalArea);
          const totalAreaText = `${totalArea} ${this.$t('acres')}`
          const totalAreaSubtext = `${this.$t('totalArea')}\n`
          const pondAreaName = `${this.$t('pondArea')}`

          this.options = {
            title: {
              text: totalAreaText,
              subtext: totalAreaSubtext,
              x: '39%',
              y: '41%',
              textAlign: 'center',
              textStyle: {
                fontWeight: 'bold',
                fontSize: 38,
                color: '#fff'
              },
              subtextStyle: {
                fontWeight: 'normal',
                fontSize: 18,
                color: '#fff'
              }
            },
            legend: {
              icon: 'circle', // 设置图标为圆形
              orient: 'vertical',
              right: '5%',
              top: 'center',
              // width: 140,
              padding: [0, 0, 15, 0],
              data: ponds.map(pond => pond.name)
            },
            tooltip: {
              trigger: 'item',
              formatter: `{a} <br/>{b} : {c} ${this.$t('acres')} ({d}%)`
            },
            series: [
              {
                name: pondAreaName,
                type: 'pie',
                radius: ['50%', '70%'],
                center: ['40%', '50%'],
                left: '10%',
                right: '15%',
                data: ponds,
                label: {
                  show: false
                },
                itemStyle: {
                  borderRadius: 10,
                  borderWidth: 2
                }
              }
            ]
          }
        } catch (error) {
          // console.log(error);
        }
      }
    }
  }
</script>
<style scoped>
  .title {
    color: #516b91;
    position: absolute;
    top: 4%;
    left: 4%;
    width: 123px;
    font-size: 17px;
    font-weight: bold;
  }
</style>
