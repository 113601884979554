<template>
  <div class="">
    <dv-border-box-12>
      <div class="relative">
        <div class="tool-bar absolute">
          <div class="" v-if="editMode" @click="handleEditMode">{{ $t('exitEdit') }}</div>
          <div class="cursor-pointer" v-else @click="handleEditMode">{{ $t('edit') }}</div>
        </div>
        <!-- 分页pagination -->
        <div class="dot-indexs">
          <div
            v-for="(item, index) in pondDistributionCount"
            :key="index"
            :class="{
              'dot-center': true,
              dot: true,
              'dot-active': selectedPondPage === index
            }"
            @click="handleSelectPondDistribution(index)"
          >
            {{ index + 1 }}
          </div>
          <div class="dot dot-center" v-if="editMode" @click="dialogVisibleAddPage = true">+</div>
          <div class="dot dot-center flex flex-center" v-if="editMode" @click="openConfirmDialog">
            <img class="w-70" src="@/assets/icons/rubbish-bin-svgrepo-com.svg" alt="" />
          </div>
        </div>
        <!-- 显示模式 -->
        <div class="pond-image-container" :key="`${rows}-${cols}`">
          <div class="flex w-full" v-if="distributionArray.length === 0">
            <div class="empty-grid flex flex-center">
              <div class="empty-grid-text cursor-pointer" @click="dialogVisibleAddPage = true">
                {{ $t('createLayout') }}
              </div>
            </div>
          </div>
          <div
            class="flex pond-background"
            v-for="(item, distributionIndex) in distributionArray"
            :key="distributionIndex"
            :style="flexItemSytle"
          >
            <div
              class="cursor-pointer"
              v-if="item.fishpondIndex == undefined && editMode"
              @click="openBindPondDialog(distributionIndex)"
            >
              {{ $t('bindPond') }}
            </div>
            <div
              v-else
              class="relative flex w-full flex-center cursor-pointer flex-wrap"
              @click="dialogOpener(distributionIndex)"
            >
              <div
                class="pond-machine-grid"
                v-for="(machine, index) in item.machineStatList"
                :key="index"
                :class="{ 'pond-error': machine && machine.relayOnlineStatus === 0 }"
              >
                <div class="machines-wpr" v-if="machine">
                  <!--  所有传感器图标 -->
                  <div class="machines-icon" v-if="machine.machineType === 'sensor'">
                    <img
                      v-if="machine.status === 0"
                      src="@/assets/icons/sensor-alert.png"
                      alt="传感器掉线动图"
                    />
                    <img
                      v-else-if="machine.status === 1"
                      src="@/assets/icons/sensor-normal.png"
                      alt="传感器正常动图"
                    />
                  </div>
                  <!-- 所有增氧机图标 -->
                  <div class="machines-icon" v-if="machine.machineType === 'aerator'">
                    <img
                      v-if="machine.relayOnlineStatus === 0"
                      src="@/assets/icons/fan-error.gif"
                      alt="增氧掉线动图"
                    />
                    <img
                      v-else-if="machine.openStatus === 0"
                      src="@/assets/icons/fan-normal-img.jpg"
                      alt="增氧正常静态图片"
                    />
                    <img
                      v-else-if="machine.isAlert === true"
                      src="@/assets/icons/fan-alert.gif"
                      alt="增氧告警动态图片"
                    />
                    <img
                      v-else-if="machine.openStatus === 1"
                      src="@/assets/icons/fan-normal.gif"
                      alt="增氧正常动态图片"
                    />
                  </div>
                </div>
              </div>
              <div class="pond-name-in-grid">{{ item.name }}</div>
            </div>
          </div>
        </div>

        <!-- 添加页面弹窗 -->
        <el-dialog
          :title="$t('addPage')"
          :visible.sync="dialogVisibleAddPage"
          width="30%"
          :modal="false"
        >
          <!--  -->
          <div class="flex flex-center gap-5">
            <el-select class="w-80px" v-model="addDistributionColValue">
              <el-option
                v-for="item in colValues"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="font-bold">&nbsp;&nbsp;X&nbsp;&nbsp;</div>

            <el-select class="w-80px" v-model="addDistributionRowValue">
              <el-option
                v-for="item in rowValues"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleAddPage = false">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="handleAddPage">{{ $t('confirm') }}</el-button>
          </span>
        </el-dialog>
        <!-- 删除页面弹窗 -->
        <el-dialog
          :title="$t('edit')"
          :visible.sync="dialogVisibleConfirmDelete"
          width="30%"
          :modal="false"
          @close="dialogVisibleConfirmDelete = false"
        >
          <p>{{ $t('confirmDelete') }}</p>
          <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisibleConfirmDelete = false">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="confirmDelete">{{ $t('confirm') }}</el-button>
          </span>
        </el-dialog>
        <!-- 绑定塘口弹窗 -->
        <el-dialog
          :title="$t('editPond')"
          :visible.sync="dialogVisibleBindPond"
          width="30%"
          :modal="false"
        >
          <!-- 塘口选择 -->
          <div class="mb-8">
            <div class="mb-4 font-bold">{{ $t('bindPondToLayout') }}</div>

            <el-select
              v-model="selectedBindPondId"
              @change="handleBindPondIdChange"
              placeholder="请选择塘口"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in pondsOptionList"
                :key="item.label + index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
          <!-- 增氧机位置选择 -->
          <div
            class="mb-8"
            v-if="
              selectedBindPondData && selectedBindPondData.memberFishpondDeviceRspVos.length > 0
            "
          >
            <div class="mb-4 font-bold">{{ $t('bindMachinePosition') }}</div>
            <div
              class="flex gap-5 mb-2"
              v-for="(item, index) in selectedBindPondData.memberFishpondDeviceRspVos"
              :key="index"
            >
              <!-- 左边显示机器接口名称(不需要选择， 只要显示名称) -->
              <div class="min-w-15 max-w-50 flex align-center">
                {{ item.name }}
              </div>
              <div class="w-full">
                <el-select placeholder="请选择" style="width: 100%" v-model="item.aeratorIndex">
                  <el-option
                    v-for="(grid, index) in pondGridOptions"
                    :key="grid.label + index"
                    :label="grid.label"
                    :value="grid.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>
          <!-- 传感器位置选择 -->
          <div
            class=""
            v-if="
              selectedBindPondData && selectedBindPondData.deviceFishpondSensorRspVos.length > 0
            "
          >
            <div class="mb-4 font-bold">{{ $t('bindSensorPosition') }}</div>
            <div
              class="flex gap-5 mb-2"
              v-for="(item, index) in selectedBindPondData.deviceFishpondSensorRspVos"
              :key="index"
            >
              <!-- 左边显示机器接口名称(不需要选择， 只要显示名称) -->
              <div class="min-w-15 max-w-50 flex align-center">
                {{ item.name }}
              </div>
              <div class="w-full">
                <el-select placeholder="请选择" style="width: 100%" v-model="item.sensorIndex">
                  <el-option
                    v-for="(grid, index) in pondGridOptions"
                    :key="grid.label + index"
                    :label="grid.label"
                    :value="grid.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
          </div>

          <span slot="footer" class="dialog-footer">
            <el-button
              v-if="showUnbindButton"
              type="danger"
              @click="handleUnbindPond('unbindPond')"
              >{{ $t('unbindPond') }}</el-button
            >
            <el-button @click="closeEditPondDialog">{{ $t('cancel') }}</el-button>
            <el-button type="primary" @click="handleSavePondData">{{ $t('confirm') }}</el-button>
          </span>
        </el-dialog>
      </div>
    </dv-border-box-12>
  </div>
</template>

<script>
  import {
    getPondDistribution,
    deletePondDistribution,
    addPondDistribution,
    getPondMachineList,
    bindPondToDistribution,
    unbindPondDistribution,
    getPondMachineListByDistributionId,
    bindMachineToPond
  } from '@/api/modules/operation'
  import i18nMixin from '@/mixins/i18nMixin'

  export default {
    mixins: [i18nMixin],
    data() {
      return {
        dialogVisibleConfirmDelete: false,
        dialogVisibleBindPond: false,
        editMode: false,
        showUnbindButton: false,

        dialogVisibleAddPage: false,
        addDistributionColValue: 1, // 添加页面列数
        addDistributionRowValue: 1, // 添加页面行数
        colValues: [
          { value: 1, label: '1列' },
          { value: 2, label: '2列' },
          { value: 3, label: '3列' },
          { value: 4, label: '4列' }
        ],
        rowValues: [
          { value: 1, label: '1行' },
          { value: 2, label: '2行' },
          { value: 3, label: '3行' },
          { value: 4, label: '4行' }
        ],
        pondGridOptions: [
          { label: '解绑', value: null },
          { label: '1', value: 0 },
          { label: '2', value: 1 },
          { label: '3', value: 2 },
          { label: '4', value: 3 },
          { label: '5', value: 4 },
          { label: '6', value: 5 },
          { label: '7', value: 6 },
          { label: '8', value: 7 },
          { label: '9', value: 8 }
        ],

        interval: null, // 定时器
        pondList: [], // 塘口列表

        pondDistributionCount: 0, // 池塘页面数量
        selectedPondPage: 0, // 当前显示的图片索引
        selectedPondPageId: null, // 当前显示的图片id
        pondDistributionList: [],

        rows: 0, // 行数 m
        cols: 0, // 列数 n

        // 塘口数据
        pondsData: [],
        pondsOptionList: [],
        selectedBindPondData: null, // 选择绑定的塘口数据
        selectedBindPondDataCache: null, // 选择绑定的塘口数据缓存
        selectedBindPondId: null, // 选择绑定的塘口ID
        selectedBindPondGrid: null, // 选择绑定的塘口索引
        // TODO: 暂时存放的数据
        mappedPondList: [], // 已经绑定的塘口列表
        distributionArray: [], // 布局分页的数组
        pondsInDistrict: [] // 当前布局分页下的塘口数据
      }
    },
    mounted() {
      // 获取布局分页数据
      this.fetchPondDistribution()
      // 获取所有塘口数据
      this.fetchAllPondData()
      this.interval = setInterval(
        () => this.fetchPondsDataByDistributionId(this.selectedPondPageId),
        5000
      ) // 然后每隔5秒刷新一次
    },
    beforeDestroy() {
      clearInterval(this.interval) // 清除定时器
    },
    methods: {
      async fetchPondDistribution() {
        try {
          const { data } = await getPondDistribution()
          this.pondDistributionCount = data.length
          this.rows = data[0].sizeX
          this.cols = data[0].sizeY
          this.pondDistributionList = data
          this.selectedPondDistribution = 0

          this.distributionArray = Array.from({ length: this.rows * this.cols }, (v, i) => i)
          // 有分页数据以后获取第一页的塘口数据
          if (data[0].id) {
            this.selectedPondPageId = data[0].id
            await this.fetchPondsDataByDistributionId(data[0].id)
          }
        } catch (error) {
          console.log('Pond Status fetchData error: ', error)
        }
      },
      // 获取所有塘口数据
      async fetchAllPondData() {
        const result = await getPondMachineList()
        this.pondsData = result.data
        const unbindedPonds = this.pondsData.filter(item => item.distributionId === undefined)
        // 数据存到localStorage里面
        localStorage.setItem('pondsData', JSON.stringify(this.pondsData))

        // 将数据处理成el-options的数据结构
        this.pondsOptionList = unbindedPonds.map(item => {
          return {
            label: item.name,
            value: item.id
          }
        })
      },
      // 选择塘口分页
      async handleSelectPondDistribution(index) {
        this.selectedPondPage = index // 选择的分页索引
        this.selectedPondPageId = this.pondDistributionList[index].id // 选择的分页id

        this.rows = this.pondDistributionList[index].sizeX
        this.cols = this.pondDistributionList[index].sizeY
        this.distributionArray = Array.from({ length: this.rows * this.cols }, (v, i) => i)
        // 获取当前分页下的塘口数据
        await this.fetchPondsDataByDistributionId(this.pondDistributionList[index].id)
      },
      async fetchPondsDataByDistributionId(distributionId) {
        const result = await getPondMachineListByDistributionId(distributionId)
        this.pondsInDistrict = result.data.map(item => {
          item.machineStatList = Array.from({ length: 9 }, () => undefined)
          // 遍历item.memberFishpondDeviceRspVos, 如果里面的aeratorIndex存在，使用aeratorIndex的值放到item.machineStatList中的对应的位置
          if (item.memberFishpondDeviceRspVos) {
            item.memberFishpondDeviceRspVos.forEach(device => {
              if (device.aeratorIndex !== undefined) {
                item.machineStatList[device.aeratorIndex] = {
                  ...device,
                  machineType: 'aerator'
                }
              }
            })
          }
          // 遍历item.deviceFishpondSensorRspVos, 如果里面的sensorIndex存在，使用sensorIndex的值放到item.machineStatList中的对应的位置
          if (item.deviceFishpondSensorRspVos) {
            item.deviceFishpondSensorRspVos.forEach(sensor => {
              if (sensor.sensorIndex !== undefined) {
                item.machineStatList[sensor.sensorIndex] = {
                  ...sensor,
                  machineType: 'sensor'
                }
              }
            })
          }

          return item
        })

        // 遍历 pondsInDistrict，如果存在 fishpondIndex，则更新 distributionArray
        this.pondsInDistrict.forEach(pond => {
          if (pond.fishpondIndex !== undefined) {
            if (pond.memberFishpondDeviceRspVos.length > 0) {
              pond.relayOnlineStatus = pond.memberFishpondDeviceRspVos.some(device => {
                return device.relayOnlineStatus === 0
              })
            }
            this.$set(this.distributionArray, pond.fishpondIndex, pond)
          }
        })
      },
      // 编辑模式
      handleEditMode() {
        this.editMode = !this.editMode
      },
      // 确认删除弹窗
      openConfirmDialog() {
        this.dialogVisibleConfirmDelete = true
      },
      // 添加页面布局
      async handleAddPage() {
        const sizeX = this.addDistributionRowValue
        const sizeY = this.addDistributionColValue
        try {
          await addPondDistribution({
            sizeX,
            sizeY,
            name: '布局' + sizeX + 'x' + sizeY
          })
          this.fetchPondDistribution()
          this.dialogVisibleAddPage = false
        } catch (error) {
          this.$message.error('添加失败')
        }
      },
      // 确认删除API
      async confirmDelete() {
        // 检查distributionArray里面确定没有绑定的塘口，如果有，提示先解绑
        const hasBindedPond = this.distributionArray.some(item => item.fishpondIndex !== undefined)
        if (hasBindedPond) {
          this.$message.error('请先解绑已绑定的塘口')
          return
        }
        await deletePondDistribution(this.pondDistributionList[this.selectedPondPage].id)
        this.fetchPondDistribution()
        this.$message.success('删除成功')
        this.selectedPondPage = 0
        await this.handleSelectPondDistribution(this.selectedPondPage)
        this.dialogVisibleConfirmDelete = false
      },
      // 打开绑定塘口弹窗
      openBindPondDialog(distributionIndex) {
        this.handleCLickPondGrid(distributionIndex)
        this.dialogVisibleBindPond = true
      },
      // 点击布局区块，显示绑定塘口弹窗
      handleCLickPondGrid(distributionIndex) {
        let bindedPond = this.distributionArray[distributionIndex]
        if (this.distributionArray[distributionIndex].id) {
          bindedPond = this.pondsData.find(
            item => item.id === this.distributionArray[distributionIndex].id
          )
        }
        if (bindedPond.fishpondIndex !== undefined) {
          this.showUnbindButton = true
          this.selectedBindPondId = bindedPond.id
          this.selectedBindPondData = bindedPond
          this.selectedBindPondDataCache = JSON.parse(JSON.stringify(bindedPond))
        } else {
          this.selectedBindPondId = null // 重置选择的塘口id
          this.selectedBindPondData = null // 重置选择的塘口数据
          this.selectedBindPondDataCache = null // 重置选择的塘口数据缓存
        }

        this.selectedBindPondGrid = distributionIndex
      },
      //  保存绑定的塘口
      async handleSavePondData() {
        // 如果没有选择塘口，return
        if (!this.selectedBindPondId) {
          this.$message.error('请选择塘口')
          return
        }
        // 保存绑定塘口
        // 从distributionArray获取当前点击的塘口的数据
        const currentPond = this.distributionArray[this.selectedBindPondGrid]
        if (!currentPond.id || currentPond.id !== this.selectedBindPondId) {
          await this.saveBindPondToGrid()
        }

        // 塘口绑定成功后， 保存塘口下机器位置数据。
        await this.saveBindAerator()

        // 增氧机保存成功以后，保存传感器位置数据
        await this.saveBindSensor()

        // 成功以后重新获取当前塘口数据，更新塘口页面
        await this.fetchAllPondData()
        await this.handleSelectPondDistribution(this.selectedPondPage)
        this.selectedBindPondDataCache = null // 重置选择的塘口数据缓存
        this.dialogVisibleBindPond = false
        this.showUnbindButton = false
      },
      async saveBindPondToGrid() {
        const params = {
          distributionId: this.pondDistributionList[this.selectedPondPage].id,
          fishpondId: this.selectedBindPondId,
          fishpondIndex: this.selectedBindPondGrid
        }
        // 绑定塘口api调用
        try {
          await bindPondToDistribution(params)
        } catch (error) {
          this.$message.error('先解除已绑定的塘口')
        }
      },

      handleBindPondIdChange(value) {
        this.selectedBindPondId = value
        this.selectedBindPondData = this.pondsData.find(item => item.id === value)
        this.selectedBindPondDataCache = JSON.parse(JSON.stringify(this.selectedBindPondData))
      },
      // 解绑塘口
      async handleUnbindPond(type) {
        let params = {}
        let result = null
        switch (type) {
          case 'unbindPond':
            params = {
              unbindId: this.selectedBindPondData.id, // 鱼塘id
              unbindType: 'fishpond' // 鱼塘
            }
            result = await unbindPondDistribution(params)
            if (result.code === 200) {
              this.$message.success('解绑成功')
            }
            await this.fetchAllPondData() // 解绑以后更新塘口数据
            this.dialogVisibleBindPond = false
            break
          case 'unbindMachine':
            params = {
              unbindType: 'aerator', // 增氧机
              mac: 'machine', // 从控制器下的数据获取，需要点击增氧机的时候获取
              relayType: 1 // 从控制器下的数据获取，需要点击增氧机的时候获取
            }
            result = await unbindPondDistribution(params)
            break
          case 'unbindSensor':
            params = {
              unbindId: 1, // 从控制器下的数据获取，需要点击传感器的时候获取
              unbindType: 'sensor' // 传感器
            }
            result = await unbindPondDistribution(params)
            break
        }
        await this.handleSelectPondDistribution(this.selectedPondPage)
        this.selectedBindPondId = null
        this.selectedBindPondData = null
      },
      closeEditPondDialog() {
        this.showUnbindButton = false
        this.dialogVisibleBindPond = false
      },
      processPondsDataInDistrict() {
        // 遍历 pondsInDistrict，如果存在 fishpondIndex，则更新 distributionArray
        this.pondsInDistrict.forEach(pond => {
          if (pond.fishpondIndex !== undefined) {
            this.distributionArray[pond.fishpondIndex] = pond
          }
        })
      },
      // 显示模式时，点击布局区块，显示绑定，换绑或者取消绑定弹窗
      // 编辑模式时，点击布局区块，显示修改塘口的机器位置绑定弹窗
      dialogOpener(distributionIndex) {
        // 如果是编辑模式
        if (this.editMode) {
          // 打开绑定塘口弹窗
          this.openBindPondDialog(distributionIndex)
        }
      },
      async saveBindAerator() {
        const machines = this.selectedBindPondData.memberFishpondDeviceRspVos
        // selectedBindPondDataCache 为选择的塘口数据缓存，遍历的machines数据，如果当前增氧机的aeratorIndex和缓存的不一样，就绑定
        for (const [index, machine] of machines.entries()) {
          const oldMachineIndex =
            this.selectedBindPondDataCache.memberFishpondDeviceRspVos[index].aeratorIndex

          // 如果oldMachineIndex == undefined，说明是新增的增氧机，直接绑定
          // 如果oldMachineIndex !== machine.aeratorIndex，说明是修改的增氧机，需要重新绑定
          // 如果oldMachineIndex === machine.aeratorIndex，说明是没有修改的增氧机，不需要重新绑定
          if (oldMachineIndex === undefined || oldMachineIndex !== machine.aeratorIndex) {
            const params = {
              fishpondId: this.selectedBindPondData.id,
              mac: machine.mac,
              relayType: machine.relayType,
              fishMachineIndex: machine.aeratorIndex,
              fishMachineType: 'aerator'
            }
            await bindMachineToPond(params)
          }
        }
      },
      // 保存绑定的传感器
      async saveBindSensor() {
        const sensors = this.selectedBindPondData.deviceFishpondSensorRspVos
        // selectedBindPondDataCache 为选择的塘口数据缓存，遍历的sensors数据，如果当前传感器的sensorIndex和缓存的不一样，就绑定
        for (const [index, sensor] of sensors.entries()) {
          const oldSensorIndex =
            this.selectedBindPondDataCache.deviceFishpondSensorRspVos[index].sensorIndex

          // 如果oldSensorIndex == undefined，说明是新增的传感器，直接绑定
          // 如果oldSensorIndex !== sensor.sensorIndex，说明是修改的传感器，需要重新绑定
          // 如果oldSensorIndex === sensor.sensorIndex，说明是没有修改的传感器，不需要重新绑定
          if (oldSensorIndex === undefined || oldSensorIndex !== sensor.sensorIndex) {
            const params = {
              fishpondId: this.selectedBindPondData.id,
              sensorId: sensor.id,
              fishMachineIndex: sensor.sensorIndex,
              fishMachineType: 'sensor'
            }
            await bindMachineToPond(params)
          }
        }
        this.selectedBindPondDataCache = null // 重置选择的塘口数据缓存
      },
      // util 检查空对象
      isEmptyObject(obj) {
        return !(
          Object.prototype.toString.call(obj) === '[object Object]' && Object.keys(obj).length === 0
        )
      }
    },
    computed: {
      flexItemSytle() {
        let styles = {}
        const { rows, cols } = this
        const witdhValue = 100 / cols + '%'
        const heightValue = 100 / rows + '%'
        styles = {
          ...styles,
          width: witdhValue,
          height: heightValue,
          justifyContent: 'center',
          alignItems: 'center'
        }
        return styles
      }
    }
  }
</script>

<style lang="scss" scoped>
  .empty-grid {
    width: 100%;
    height: 100%;
    justify-content: 'center';
    align-items: center;
    background: linear-gradient(to right, #002566, #0044cc 50%, #002566);
  }
  .machines-wpr {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .machines-icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    transition: all 0.3s;
    // cursor: pointer;
    // &:hover {
    //   transform: scale(2.5);
    //   background-color: white;
    //   z-index: 9999;
    // }
    & img {
      object-fit: contain;
      min-height: 27px;
      max-height: 71%;
    }
  }

  .pond-name-in-grid {
    font-size: 150%;
    font-weight: bold;
    color: rgba(232, 232, 232, 0.418);
    // place this absolute at center
    position: absolute;
    text-align: center;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .pond-machine-grid {
    width: calc(33.333% - 1px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33%;
  }
  /* 第0个格子：右，下 */
  .pond-machine-grid:nth-child(1) {
    border-right: 1px solid #636363;
    border-bottom: 1px solid #636363;
  }

  /* 第1个格子：右，下 */
  .pond-machine-grid:nth-child(2) {
    border-right: 1px solid #636363;
    border-bottom: 1px solid #636363;
  }

  /* 第2个格子：下 */
  .pond-machine-grid:nth-child(3) {
    border-bottom: 1px solid #636363;
  }

  /* 第3个格子：右，下 */
  .pond-machine-grid:nth-child(4) {
    border-right: 1px solid #636363;
    border-bottom: 1px solid #636363;
  }

  /* 第4个格子：右，下 */
  .pond-machine-grid:nth-child(5) {
    border-right: 1px solid #636363;
    border-bottom: 1px solid #636363;
  }

  /* 第5个格子：下 */
  .pond-machine-grid:nth-child(6) {
    border-bottom: 1px solid #636363;
  }

  /* 第6个格子：右 */
  .pond-machine-grid:nth-child(7) {
    border-right: 1px solid #636363;
  }

  /* 第7个格子：右 */
  .pond-machine-grid:nth-child(8) {
    border-right: 1px solid #636363;
  }

  .grid-item {
    background-size: cover;
    background: linear-gradient(180deg, #87ceeb, #5680e9);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  /* 最后一列不需要右边框 */
  .grid-item:nth-child(3n) {
    border-right: none;
  }

  /* 最后一行不需要底边框 */
  .grid-item:nth-last-child(-n + 3) {
    border-bottom: none;
  }
  .button-container {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s;
  }
  .bind-button {
    padding: 5px;
    cursor: pointer;
  }
  .oxy-location-button {
    margin-left: 5px;
    padding: 5px;
    cursor: pointer;
  }
  .content-box {
    z-index: 10;
    position: absolute;
    top: 50%;
    width: 200px;
    height: 300px;
    overflow-y: auto; /* 允许垂直滚动 */
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 100; /* 确保它在其他元素上方 */
  }
  .bind-pond-menu-box {
    left: 50%;
  }
  .bind-location-menu-box {
    left: 78%;
  }
  .content-box ul {
    padding: 0;
    margin: 0;
    list-style: none;
  }
  .content-box ul li {
    color: black;
    padding: 5px 0px;
    cursor: pointer;
  }
  .relative {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .dot-indexs {
    position: absolute;
    display: flex;
    z-index: 1;
    gap: 10px;
    right: 0;
    top: -25px;
  }
  .dot {
    background-color: rgb(222, 222, 222);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .dot-center {
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  .dot.dot-active {
    background-color: white;
    color: black;
  }
  .pond-image-container {
    position: absolute;
    right: 50%;
    transform: translate(50%, 6px);
    width: 98.5%;
    height: 338px;
    border-radius: 12px;
    overflow: hidden;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .machine-content-box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px;
    width: 100%;
    height: 100%;
  }
  .machine-grid {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    /* 如果你想有固定的间距，你可以在这里进行调整 */
  }
  .grid-hovered {
    background-color: #62a2cd;
    color: white;
  }
  .switch-pond-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .switch-pond-modal h1,
  .switch-pond-modal p {
    text-align: center;
    margin-bottom: 2rem;
    color: black;
  }
  .switch-pond-modal .btn-group {
    display: flex;
    justify-content: center;
    gap: 20px;
  }
  .switch-pond-modal .btn-group button {
    color: black;
    padding: 10px 20px;
    border: none;
    border-radius: 7px;
    cursor: pointer;
  }
  .switch-pond-modal .btn-group .btn-success {
    background-color: #88ff98;
  }
  .tool-bar {
    top: -8%;
    left: 0;
    display: flex;
    gap: 1rem;
  }
  .pond-error {
    background: red;
    opacity: 0.5;
    animation: blink 1s infinite; /* 1s表示闪烁周期为1秒，infinite表示动画无限次数播放 */
  }
  @keyframes blink {
    0%,
    100% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.25;
    }
  }
  .pond-image-container .pond-background {
    border: 1px solid #0f8dd3;
    background: linear-gradient(to right, rgb(0, 37, 102), rgb(4 51 155) 50%, rgb(0, 37, 102));
  }
</style>
