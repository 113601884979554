<template>
  <div class="">
    <dv-border-box-12>
      <!-- <div class="">动态告警列表</div> -->
      <dv-scroll-board :config="scrollData" style="width: 100%; height: 350px" />
    </dv-border-box-12>
  </div>
</template>

<script>
  import { getMachineAlertApi } from '@/api/modules/operation'
  import i18nMixin from '@/mixins/i18nMixin'

  export default {
    mixins: [i18nMixin],
    data() {
      return {
        interval: null, // 定时器
        scrollData: {}
      }
    },
    mounted() {
      this.fetchData() // 立即执行一次
      this.interval = setInterval(this.fetchData, 15000) // 然后每5秒执行一次
    },
    beforeDestroy() {
      clearInterval(this.interval) // 清除定时器
    },
    methods: {
      async fetchData() {
        try {
          const res = await getMachineAlertApi()
          this.$store.dispatch('processAlerts', res.data)
          const firstFive = res.data.slice(0, 10)
          this.scrollData = {
            header: [this.$t('object'), this.$t('alert'), this.$t('date')],
            data: firstFive.map(item => {
              return [item.relayNames, item.typeName, item.createTime.slice(0, 16)]
            }),
            rowNum: 10
          }
        } catch (error) {
          console.log(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped></style>
